export default {
  "navbar": {
    "education": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukacja"])},
    "experience": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doświadczenie"])},
    "skills": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiejętności"])},
    "projects": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekty"])},
    "contact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])},
    "images": {
      "sun": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień motyw na jasny"])},
      "moon": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień motyw na ciemny"])},
      "polishFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień język na polski"])},
      "greatBritainFlag": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zmień język na angielski"])}
    }
  },
  "landingPage": {
    "myNameIs": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Hej, nazywam się"])},
    "name": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maciek Iwaniuk."])},
    "description": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Jestem studentem informatyki, skupionym na podnoszeniu swoich kompetencji. Wiążę swoją przyszłość z branżą IT i stale rozwijam się, aby być na bieżąco z najnowszymi technologiami."])}
  },
  "education": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Edukacja"])},
    "educationItems": {
      "zse": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Zespół Szkół Elektrycznych w Białymstoku"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Dzięki edukacji w tej placówce zdobyłem podstawową wiedzę z zakresu informatyki. Poszerzyłem swoje horyzonty z zakresu wiedzy na temat baz danych, tworzenia aplikacji desktopowych oraz webowych. "])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Technik programista"])}
      },
      "universityOfTechnology": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Politechnika Białostocka"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Poszerzę wiedzę związaną z IT."])},
        "major": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Informatyka"])}
      }
    }
  },
  "experience": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Doświadczenie"])},
    "experienceItems": {
      "merinosoft": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwój aplikacji webowej związanej z zarządzaniem pracownikami przez firmy."])},
        "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programista PHP"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["sie 2021 - gru 2022 "])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(1 rok 5 mies.)"])}
      },
      "ebrand": {
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Rozwój rozproszonego systemu opartego na architekturę mikroserwioswą związanego z ochroną marek online. Integracje z licznymi zewnętrznymi systemami przez API."])},
        "profession": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Programista PHP"])},
        "period": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["kwi 2023 - wrz 2023 "])},
        "length": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["(6 mies.)"])}
      }
    }
  },
  "skills": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Umiejętności"])},
    "useRegularly": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Regularnie używam"])},
    "hadContact": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Miałem styczność z"])}
  },
  "projects": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekty"])},
    "projectsItems": {
      "shopSystem": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["System sklepu"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Backend prostego systemu sklepu. Projekt oparty jest na CQRS wykonanym jako modułowy monolit. Foldery są posegregowane przy użyciu podejścia Domain-Driven-Design."])}
      },
      "demoCryptoExchange": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Demo giełda kryptowalut"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Projekt demo giełdy kryptowalut, która symuluje rzeczywiste funkcjonowanie giełdy, jednak używa fikcyjnej waluty. Dane dotyczące cen kryptowalut są pobierane z API udostępnianego przez giełdę Binance."])}
      },
      "snakeWebApp": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snake - aplikacja webowa"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja obsługuje dane przesyłane przez grę na komputer napisaną w pythonie. Posiada rozbudowane funkcjonalności kont użytkowników wraz z obszernym, bogatym w funkcjonalności panelem administratora."])}
      },
      "snakeGame": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Snake - gra"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gra odczytuje oraz zapisuje dane poprzez API aplikacji webowej. Użytkownik rywalizuje z innymi użytkownikami poprzez ranking, zdobywa walutę, którą może następnie wydać na ulepszenia lub skórki poprawiające odczucia z rozgrywki."])}
      },
      "minesweeper": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Saper"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Gra w sapera z możliwością wyboru trudności gry oraz zapisywaniem wyborów jak i rekordów użytkownika do lokalnej pamięci przeglądarki."])}
      },
      "portfolio": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Portfolio"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Strona internetowa streszczająca informacje o mojej osobie. Zawarte są w niej wiadomości na temat mojej edukacji, doświadczenia komercyjnego czy projektów."])}
      },
      "youtubeDownloader": {
        "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Pobieracz YouTube"])},
        "content": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Aplikacja desktopowa która umożliwia pobieranie filmików z YouTube. Posiada zakładkę z ustawieniami pobierania, gdzie użytkownik może m.in. wybrać jakość pobieranych treści czy zmienić rozszerzenie."])}
      }
    }
  },
  "contact": {
    "title": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Kontakt"])}
  },
  "footer": {
    "allRightsReserved": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["© Wszelkie prawa zastrzeżone"])}
  },
  "author": (ctx) => {const { normalize: _normalize } = ctx;return _normalize(["Maciek Iwaniuk"])}
}